.signup-form-container {
    width: 100%;
    padding: 30px 10%;
    border-radius: 0px;
    background-color: #373736;
    height: auto;
    box-shadow: 0px 1px 11px #111110;
    z-index: 8;
}
.signup-input-group {
  width: 40%;
  padding: 0 5%;
  display: inline-flex;
  align-content: center;
  flex-wrap: wrap;
  letter-spacing: .01rem;
}
.signup-form-container legend {
  font-size: 28px;
  color: #f98c00;
}
.signup-form-group {
  width: 40%;
  padding: 0 1%;
  display: inline-flex;
  flex-direction: column;
}
.large-width {
  width: 82%;
}
.signup-form-group label {
  text-align: left;
  font-size: 20px;
  color: #f98c00;
}
.signup-form-group label span {
  color: #ffffff;
  font-size: 18px;
}
.radio {
  margin-bottom: 30px;
}
.radio-select {
  width: 86%;
  margin-bottom: none;
}
.radio-label {
  font-size: 16px !important;
}
.no-input-margin {
  margin: 20px 0px;
}
.signup-form-control, .react-datepicker__input-container input{
  background: #f7f9fc;
  border: none;
  border: 1px solid #CACACA;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  text-indent: 20px;
  height: 32px;
  margin-bottom: 20px;
}
.react-datepicker__input-container input {
  width: 98%;
}
input:disabled{
  background-color: #373736;
  cursor: not-allowed;
}
  .signup-message {
    height: 160px;
    /* margin: 10px; */
    padding: 20px;
    text-indent: 0px;
    resize: none;
    font-family: Sans-Serif;
  }
  .signup-btn-primary {
    background: #f98c00;
    border: none;
    border-radius: 5px;
    padding: 7px;
    box-shadow: none;
    margin: 0px 0 0px 0;
    /* font-weight: bold; */
    font-size: 1.5em;
    text-shadow: none;
    outline: none !important;
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Teko', Sans-Serif;
  }
  .signup-btn-primary:disabled {
    background: #f98c009e;
    cursor: not-allowed;
  }
  .react-tel-input .form-control {
    width: 100%;
    height: 36px;
  }
  .react-tel-input .country-list .country {
    padding: 7px 9px;
    font-size: 14px;
    text-align: left;
    width: 100%;
}
fieldset {
    margin-top:10px;
    padding:3%;
}
select {
    /***height: 36px !important;***/
    padding-right: 20px;
    margin-left: auto;
    width: 50%;
}
.checkbox-container {
  flex-wrap: wrap;
  display: flex;
  padding: 15px 0 15px 0;
}
.checkbox-item {
  width: 48%;
  padding: 1%;
  text-align: left;
}
.checkbox-item label {
  padding-left: 2%;
  color: #ffffff;
  font-size: 18px;
}
input:checked {
  background-color: #f98c00;
  accent-color: #f98c00;
}
.permission-box {
  color: #ffffff!important;
  font-size: 18px !important;
}
.checkbox-top {
  margin-top: -22px;
}
textarea {
  height: 160px !important;
  padding: 20px;
  resize: none;
  text-indent: 0!important;
  font-family: sans-serif;
}
.react-datepicker__day--selected {
  background-color: #f98c00!important;
}
.react-datepicker__day--keyboard-selected {
  background-color: #ffcf90!important;
}

/******************************************************************************
					For mobile phones:
******************************************************************************/
@media only screen and (max-width: 1000px)
			/*, screen and (max-height: 600px) { */
{
  .signup-form-container {
    width: 98%;
    padding: 30px 1%;
  }
  .signup-form-group {
    width: 90%;
    padding: 0 5%;
  }
  .radio-select {
    width: 86%;
    margin-bottom: 20px;
  }
  textarea {
    height: 160px !important;
    padding: 20px 2%;
    width: 96%;
    resize: none;
  }
}