body {
  background-color: #2e3237;
  color: #ffffff;
  font-family: 'Teko', Sans-Serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  background-image: url(../public/img/arrowbg.png);
  background-size: 15px;
}
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background-color: #252525;
  display: none;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
