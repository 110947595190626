.App {
  text-align: center;
  /* background-color: #111110; */
}
::-webkit-scrollbar {
  width: 0px;
}
/* html {
  scroll-behavior: smooth;
} */
/*********************************************************************************
                         Fonts and basic css elements
*********************************************************************************/
h1 {
  display: block;
  font-size: 30px;
  padding: 18px 32px;
  margin: 0px;
  letter-spacing: 1px;
  font-weight: normal;
  font-family: 'Teko', Sans-Serif;
  /* -webkit-text-stroke: 2px #111110; */
}
h2 {
  display: block;
  font-size: 30px;
  margin: 0px;
  padding: 0px;
  letter-spacing: 1px;
  /* font-weight: normal; */
  font-family: 'Teko', Sans-Serif;
}
h3 {
  display: block;
  font-size: 22px;
  margin: 36px 0 0 30px;
  padding: 0px;
  letter-spacing: 1px;
  /* font-weight: normal; */
  font-family: 'Teko', Sans-Serif;
}
h4 {
  display: block;
  font-size: 24px;
  color:#f98c00;
  padding: 0px;
  margin: 0px;
}
p {
  margin: 20px 30px;
  font-weight: normal;
  font-family: 'Lao UI', Sans-Serif;
  font-size: 20px;
  text-align: justify;
}
.aR {
  float: right;
}
.aL {
  float: left;
}
.profileBtn {
  cursor: pointer;
  transition: all 0.2s ease;
}
.profileBtn:hover:after {
  opacity: 1;
}
.profileBtn:after {
  opacity: 0;
  content: "";
  height: 0px;
  width: 0px;
  position: fixed;
  top: 53px;
  margin-left: -39px;
  border-bottom: 14px solid #db6700;
  border-right: 14px solid #db670000;
  border-left: 14px solid #db670000;
  transition: all 0.2s ease;
}
.profileBtn a {
  text-decoration: none;
  color: #ffffff;
}
.hide {
  opacity: 0!important;
}
.contentContainer {
  width: 82%;
  margin-left: 18%;
}
blockquote {
  border: 2px solid black;
    border-radius: 5px;
    padding: 15px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #f9f9f9;
}
blockquote > p {
  font-family: 'Teko', Sans-Serif;
  color: #f98c00;
  display: block;
  font-size: 25px!important;
  font-style: italic;
  margin: 0;
  text-align: center;
}
/*********************************************************************************
OVERLAY WHEN LOADING OR IF THERE IS AN ERROR IN THE APP
**********************************************************************************/
.loader{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index:300;
  background-color:#0e0e0e;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  text-align: center;
}

.loader p {
  display: inline-block;
  text-transform: uppercase;
  font-family: teko;
  text-align: center;
  font-size: 11em;
  margin:0;
  font-weight: 300;
  transform: scale(.5);
  color: #ffffff00;
  -webkit-text-stroke: 2px rgb(255, 255, 255);
}
.errorPage {
  height: 100vh;
  width: 100%;
  background-color:#0e0e0e;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.errorPage h1 {
  font-size: 13vw;
  color: #f98c00;
  height: 200px;
  margin-bottom:50px;
  -webkit-text-stroke: 2px rgb(255, 255, 255);
}
:root {
  --effect: hover 1.5s linear infinite;
}

.loader p:nth-child(1) {
  animation: var(--effect);
}

.loader p:nth-child(2) {
  animation: var(--effect) .10s;
}

.loader p:nth-child(3) {
  animation: var(--effect) .20s;
}

.loader p:nth-child(4) {
  animation: var(--effect) .30s;
}

.loader p:nth-child(5) {
  animation: var(--effect) .40s;
}

.loader p:nth-child(6) {
  animation: var(--effect) .50s;
}

.loader p:nth-child(7) {
  animation: var(--effect) .60s;
}
.loader p:nth-child(8) {
  animation: var(--effect) .70s;
}
.loader p:nth-child(9) {
  animation: var(--effect) .80s;
}
.loader p:nth-child(10) {
  animation: var(--effect) .90s;
}

@keyframes hover {
  0% {
    transform: scale(.5);
    color: #ffffff;
    -webkit-text-stroke: 2px rgb(255, 255, 255);
  }

  20% {
    transform: scale(1);
    color: rgb(255, 162, 0);
    -webkit-text-stroke: 3px rgb(243, 239, 232);
    filter: drop-shadow(0 0 1px #db670000)drop-shadow(0 0 1px #db670000)drop-shadow(0 0 3px #db670000)drop-shadow(0 0 5px #db670000);
  }

  50% {
    transform: scale(.5);
    color: #db670000;
    -webkit-text-stroke: 2px rgb(255, 255, 255);
  }


}


/*********************************************************************************
                        Header for desktop and mobile inlcuding Icons
*********************************************************************************/
.header, .mobileHeader {
  line-height: 30px;
  padding: 0px 0px 0px 0px;
  letter-spacing: 0.05em;
  text-align: left;
  border-top: 1px solid #db6700;
  border-bottom: 6px solid #db6700;
  background-color: #f98c00;
  box-shadow: 0px 1px 11px #111110;
  z-index: 100;
  opacity: 1;
  transition: all 0.3s ease-in;
}
.header {
  width: 82%;
  margin-left: 18%;
  position: fixed;
}
.header h1 {
  -webkit-text-stroke: 0px rgb(21 21 21);
}
.mobileHeader {
  justify-content: center;
  display: none;
  width: 100%;
  position: fixed;
  left: 0;
}
.header-btn {
  width: 30px;
  height: 30px;
  margin: 15px;
}
.header-profile {
  display: block;
}
.header-logo {
  background-image: url(../public/img/ssk-header.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 60px;
  width: 80%;
}
/*-------------------Burger Icon------------------------*/
.button-animation {
  height: 42px;
  width: 42px;
  margin: 10px;
  cursor: pointer;
  border: 3px solid white;
  border-radius: 44px;
  transition:all 0.2s;
}
.button-animation:hover {
  height: 48px;
  width: 48px;
  margin: 5px;
  cursor: pointer;
  border: 3px solid white;
  border-radius: 44px;
}
.burger-menu {
  background-color: #f98c00;
  padding: 8px;
  width: 23px;
  height: 23px;
  margin: 6px 5px 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border: 3px solid white;
  border-radius: 44px;
  outline: none !important;
}
.burger-menu div {
  width: 100%;
  height: 4px;
  background: #ffffff;
  border-radius: 1000px;
  opacity: 1;
  transition: all .3s ease;
}
.burger-menu div:nth-child(2) {
  margin: 3px 0;
}
.burger-menu.active div:nth-child(1) {
  transform: rotate(-45deg) translateY(4px) translateX(-4px);
}
.burger-menu.active div:nth-child(2) {
  opacity: 0;
}
.burger-menu.active div:nth-child(3) {
  transform: rotate(45deg) translateY(-6px) translateX(-6px);
}
.menu-ic {
  /* background-image: url(../public/img/icons/burg.png); */
  background-image: url(../public/img/icons/ssk-logo-icon-or.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin: 6px;
}
.profile-ic {
  background-image: url(../public/img/icons/burg.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.logo {
  position: relative;
  margin: 7% 7% 7% 7%;
  height: 266px;
  background-image: url(../public/img/sskshield_logo.png);
  background-repeat: no-repeat;
  background-size: 100%;
	/* animation: rotateAnimation 3s linear infinite; */
}
@keyframes rotateAnimation {
  0% {transform: rotateY(0deg);background-image: url(../public/img/ssklogo.png);}
  /* 20% {transform: rotateY(0deg);background-image: url(../public/img/ssklogo.png);} */
  35% {transform: rotateY(90deg);background-image: url(../public/img/ssklogo.png);}
  36% {transform: rotateY(90deg);background-image: url(../public/img/sskshield_logo.png);}
  46% {transform: rotateY(0deg);background-image: url(../public/img/sskshield_logo.png);}
  /* 71% {transform: rotateY(0deg);background-image: url(../public/img/sskshield_logo.png);} */
  86% {transform: rotateY(90deg);background-image: url(../public/img/sskshield_logo.png);}
  87% {transform: rotateY(90deg);background-image: url(../public/img/ssklogo.png);}
  100% {transform: rotateY(0deg);background-image: url(../public/img/ssklogo.png);}
}
/**********************************************************
                      Mobile Top Nav and Mobile Menu
***********************************************************/
.mobileMenu {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  background-color: #313130;
  top: 67px;
  overflow-y: scroll;
  transition: all .6s ease-in-out;
}
.mobileMenuOpen {
  visibility: hidden;
  opacity: 1;
}
.sideContainer {
  background: #111110;
  font-family: teko;
  height: 98%;
  width: 16%;
  padding: 1%;
  float: left;
  position: fixed;
  z-index: 1;
  box-shadow: inset 0 0 4px rgb(255 255 255 / 24%);
  left: 0;
  overflow-y: auto;
}
.mobileMenu .sideNavContainer {
  padding: 0px 0px 67px 0px;
}
.mobileMenu .sideNavContainer li {
  margin: 2%;
  outline: none;
  width: 92%;
  padding: 2%;
  outline: none !important;
}
.mobileMenu .sideNavContainer li a {
  outline: none;
  width: 100%;
  padding: 0%;
  text-align: center;
}
.sideNavContainer {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-top:88px;
}
/**********************************************************
                      Side Navigation for Desktop
***********************************************************/
.sideNavContainer li {
  box-shadow: inset 0 0 6px rgb(255 255 255 / 24%);
  border:#111110 solid 3px;
  border-radius: 36px 0px 0px 36px;
  margin-bottom: 0px;
  outline: none;
  width: 96%;
  padding: 2%;
  transition: all 0.4s;
}
.sideNavContainer li:hover {
  box-shadow: inset 0 0 0px rgb(249,140,0);
  animation: borderPulse 1s infinite;
}
.sideNavContainer li:active {
  border: solid #f98c00 3px;
}
.sideNavContainer li:before {
  content: '';
  display: block;
  background-image: url(../public/img/icons/home-ic.png);
  background-color: #111110;
  background-repeat: no-repeat;
  background-size: 32px;
  width: 38px;
  height: 38px;
  border-radius: 25px;
  float: left;
  margin: 2px 11px 0 7px;
  background-position: center;
  transition: all 0.6s;
}
.sideNavContainer li:hover:before {
  animation: backgroundPulse 1s infinite;
}
.sideNavContainer li:nth-child(2):before { 
  background-image: url(../public/img/icons/ssk-logo-icon.png);
}
.sideNavContainer li:nth-child(3):before { 
  background-image: url(../public/img/icons/sanda-icon.png);
}
.sideNavContainer li:nth-child(4):before { 
  background-image: url(../public/img/icons/cont-ic.png);
}
.sideNavContainer li a {
  text-decoration: none;
  background-color: transparent;
  font-size: 30px;
  padding: 0px 0% 0px 13%;
  color: #f98c00;
  display: block;
  width: 87%;
  transition: 0.4s;
  z-index: 10;
  text-align: left;
}
.sideNavContainer li ul {
  display: none;
  list-style: none;
  padding: 0px;
}
.sideNavContainer li ul li {
  width: 100%;
  padding: 0px;
}
.sideNavContainer li ul li a {
  display: block;
  text-decoration: none;
  background: none;
  color: #e3e7f1;
  text-shadow: 1px 1px 0px #000;
  background-color: #1c1c1c;
  font-size: 1.4vw;
  line-height: 43px;
  padding: 0% 0% 0% 30%;
  width: 70%;
  font-weight: normal;
}

@keyframes backgroundPulse {
  0%, 100% {
    background-color: #f98c00;
  }
  50% {
    background-color: #ffab3d;
  }
}
@keyframes borderPulse {
  0%, 100% {
    border-color: #f98c00;
  }
  50% {
    border-color: #ffab3d;
  }
}
/***************************************************************
            Top of page Banner and Title
**************************************************************/

.banner {
  min-height: 600px;
  background-position-y: -145px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 600px;
  background-color: rgba(0, 0, 0, 0.5); /* Change the color and opacity as needed */
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 28%, rgba(0,0,0,0.0) 100%);
}
.background {
   /* Add the blur effect */
   filter: blur(8px);
   -webkit-filter: blur(8px);
    /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.main {
  background-position: inherit;
  height: 740px;
}
.main::before {
  min-height: 740px;
}
.title, .contact-title {
  font-size: 16em;
  font-family: teko;
  font-weight: 500;
  color: #ffffff;
  height:224px;
  top: 50px;
  text-shadow: 2px 2px 20px #111110;
  opacity: 1.0;
  transform: scale(1);
  transition-duration: 0.5s;
}
.titleDesc {
  font-size: 5em;
  font-family: teko;
  font-weight: 500;
  color: #ffffff;
  /* letter-spacing: 0.05em; */
  line-height: 175px;
  position: relative;
  /* top: 50px; */
  /* padding-top: 114px; */
  text-shadow: 2px 2px 20px #111110;
  opacity: 1.0;
  transform: scale(1);
  transition-duration: 0.5s;
}
.titleDesc a {
  color:rgb(255, 162, 0);
  text-decoration: none;
}
.titleBtn {
  font-size: 2em;
  font-family: 'Teko', Sans-Serif;
  /*text-shadow: 1px 1px 3px #111110;*/
  opacity: 1.0;
  transform: scale(1);
  transition-duration: 0.5s;
  background-color: #f98c00;
  padding: 10px 30px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  color:#ffffff;
  text-decoration: none;
  cursor: pointer;
  animation: backgroundPulse 2s infinite;
}
.contact {
  min-height: 800px;
}
.contact-title {
  display: block;
 
}
.contact-title h1 {
  font-size: 3rem;
  padding: 18px 32px;
  margin: 0px;
  letter-spacing: 1px;
  font-weight: normal;
  font-family: 'Teko', Sans-Serif;
}

/****************************************************************
        Second white background sub-banner
****************************************************************/
.subBanner {
  width: 100%;
  padding: 0px 0px 0px 0px;
  height: auto;
  float: left;
  background-color: #d8d8d8;
  color: #111110;
  display: inline-flex;
  flex-wrap: nowrap;
}
.subBanner ul {
  font-size: 20px;
  font-weight: normal;
  font-family: 'Lao UI', Sans-Serif;
}
.summary {
  min-height: 200px;
  width: 46%;
  padding: 4%;
  text-align: justify;
}
.summary h2 {
  width: 100%;
  line-height: 50px;
  font-weight: 500;
  margin-left: 0px;
  border-radius: 10px 0px 0px 10px;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(247,139,0,1) 0%, rgba(14,14,14,1) 9%);
  color: #eeeeee;
  text-align: center;
  box-shadow: 2px 3px 5px #11111069;
}
.summary .icon {
  height: 40px;
  width: 40px;
  margin: 5px 5px 5px 15px;
  position: absolute;
  background-image: url(../public/img/icons/quest-icon.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.summary .jrn {
  background-image: url(../public/img/icons/journey4-icon.png);
}
.summary h2:before {
  content: '';
  display: block;
  width: 10%;
  color: #eeeeee;
  text-align: center;
  float: left;
  background-image: url(../public/img/icons/quest-icon.png);
  background-size: 100%;
  border-bottom: 50px solid #f78b00;
  border-right: 36px solid #0e0e0e;
}
.summary a {
  color:#f78b00;
  text-decoration: none;
  font-weight: bold;
}

/*******************************************************
    Page content containers
**********************************************************/
.pageContent {
  font-family: sans-serif;
  padding: 3% 10% 3% 10%;
  width: 80%;
  float: left;
  text-align: justify;
}
.pageContentWhite {
  background-color:#d8d8d8;
  color:#0e0e0e;
  padding: 7% 10% 7% 10%;
  float: left;
  text-align: justify;
  width: 80%;
}
.pageContent ul, .pageContentWhite ul {
font-size: 20px;
font-weight: normal;
font-family: 'Lao UI', Sans-Serif;
}
.pageContent ul li, .pageContentWhite ul li {
list-style: circle;
line-height: 30px;
margin-left: 30px;
}
.pageContent p, .pageContent h2, .pageContent h4, .pageContentWhite p, .pageContentWhite h2, .pageContentWhite h4 {
  width: 90%;
}
.pageContent p, .pageContentWhite p {
  font-size: 20px;
}
.pageContent a, .pageContentWhite a {
  color: #f98c00;
  text-decoration: none;
}
.pageContent h4, .pageContentWhite h4 {
  margin: 20px 30px;
  font-size: 25px;
  font-style: italic;
}

/*************************************************************
                          Page Slider
************************************************************/
.slider {
  width: 90%;
  margin: 5%;
  min-height: 500px;
  border-top: 7px solid #f78b00;
  border-bottom: 7px solid #f78b00; 
  border-radius: 15px;
}
.top-border, .bottom-border {
  height: 0px;
  width: 100%;
  float: left;
}
.top-border:before, .top-border:after, .bottom-border:before, .bottom-border:after {
  content: "";
  width: 158px;
  height: 120px;
  background-image: url(../public/img/style/slider-border.png);
  background-color: transparent;
}
.top-border:before, .bottom-border:before {
  float: left;
}
.top-border:after, .bottom-border:after {
  float: right;
}
.top-border:before {
  margin: -7px 0px 0px -11px;
}
.top-border:after {
  margin: -7px -11px 0px 0px;
  transform: scaleX(-1);
}
.bottom-border:before {
  margin: -113px 0px 0px -11px;
  transform: scaleY(-1);
}
.bottom-border:after {
  margin: -113px -11px 0px 0px;
  transform: scaleY(-1) scaleX(-1);
}
.selection-bar {
  width: 100%;
  height: 80px;
  background-color: #1c1c1b;
  margin: 0px;
  border-radius: 0px 0px 0px 0px;
  border-bottom: 5px solid #1c1c1b00;
}
.selection-bar h4 {
  margin: 0;
  width: 33.33%;
  float: left;
  text-align: center;
  font-size: 3em;
  line-height: 80px;
  font-style: normal;
  letter-spacing: 0.05em;
  font-family: 'teko';
  font-weight: normal;
  color: #ffffff;
}
.active-bar {
  border-bottom: 5px solid #ffffff;
}
.slider-viewport {
  width: 90%;
  padding: 5%;
  height: 350px;
  background-color:#27272791;
  color: #e3e3e3;
  text-align: center;
  overflow:hidden;
  position: relative;
}
.slider-viewport p {
  margin: 0px;
  padding: 0px;
  text-align: center;
  width: 100%;
}
.slider-slider {
  height: 100%;
  width: 275%;
  position: absolute;
  margin-left: -100%;
  transition: all 0.4s ease-in-out;
}
.slide-right {
  margin-left: 0%;
}
.slider-cell {
  float: left;
  height: 80%;
  width: 27%;
  padding: 0% 3%;
  /* display: none; */
  transition: all 0.3s ease;
  overflow: hidden;
}
.slider-cell h4 {
  color: #ffffff;
  font-weight: normal;
  font-style:normal;
  letter-spacing: 0.05em;
  font-family: 'teko';
  font-size: 50px;
  width: 100%;
  margin: 0px 0px 40px 0px;
}
.slider-button {
  height: 25px;
  width: 25px;
  padding: 10px;
  background-color: #eeeeee;
  border-radius: 25px;
  /* margin-top: -30%; */
  opacity: 0.7;
  cursor: pointer;
  user-select: none;
}
.slider-button:hover {
  opacity: 1;
}
.slider-button div {
  background-color: #292b2d;
  height: 5px;
  width: 100%;
  border-radius: 30px;
}
.slider-button div:nth-child(1) {
  transform: rotate(-40deg) translateY(2px) translateX(-3px);
}
.slider-button div:nth-child(2) {
  transform: rotate(40deg) translateY(10px) translateX(7px);
}
.previous {
  float: left;
  margin-left: 55px;
}
.next {
  float: right;
  margin-right: 55px;
  transform: scaleX(-1);
}
.pop-up-open {
  background-color: #ffffff;
  border-radius: 20px;
  height: 40px;
  padding: 1% 2% 1% 2%;
  font-size: 18px;
  border: none;
  cursor: pointer;
  margin-top: 55px;
}

/*************************************************************
                          Footer
************************************************************/
.footer {
  width: 82%;
  margin-left: 18%;
  min-height: 200px;
  padding-top: 40px;
  background-color: #f98c00;
  background: -moz-linear-gradient(top, #f98c00 0%, #ff9900 100%);
  background: -webkit-linear-gradient(top, #f98c00 0%,#ff9900 100%);
  background: linear-gradient(to bottom, #f98c00 0%,#b86800 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dda442', endColorstr='#b88428',GradientType=0 );
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: 0px 1px 11px #111110;
}
.footer a {
  color: #ffffff;
  font-size: 20px;
  text-decoration: underline;
}
.footer ul{
  width: 200px;
  margin-top:40px;
  margin:0px;
  padding:0px;
  text-align: left;
}
.footer ul li {
  list-style: none;
}
.footer ul li ul{
  width: 200px;
  margin:0px;
  padding: 0px;
}
.footer ul li ul a{
  text-decoration: none;
}
/***********************************************
	Contact Form
***********************************************/
.contact-form {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: -170px;
  display: flex;
  /* flex-direction: row; */
  /* flex-wrap: nowrap; */
  justify-content: space-evenly;
}
.form-title {
  color: #ffffff;
  padding-left: 0px;
}
.interest-form {
  padding-top: 0px;
  margin-top: 0px;
}
.form-container {
  border-radius: 10px;
  padding: 30px;
  background-color: #373736;
  height: 560px;
  box-shadow: 0px 1px 11px #111110;
  z-index: 10;
}
.med-width {
  width: 500px;
  height: auto;
  font-family: 'Teko', Sans-Serif;
}
.input-group {
  display: inline-flex;
  align-content: center;
  flex-wrap: wrap;
}
.form-group {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
.form-group label {
  text-align: left;
  font-size: 20px;
  color: #f98c00;
}
.form-group label span {
  color: #ffffff;
}
.form-control {
  background: #f7f9fc;
  border: none;
  border-bottom: 1px solid #dfe7f1;
  border-radius: 3px;
  box-shadow: none;
  outline: none;
  text-indent: 20px;
  height: 42px;
  /* margin-left: 10%; */
  /* width: 50%; */
  margin-bottom: 20px;
}
.message {
  height: 160px;
  /* margin: 10px; */
  padding: 20px;
  text-indent: 0px;
  resize: none;
  font-family: Sans-Serif;
}
.notice-text {
  color: #ffffff;
}
.btn-primary {
  background: #f98c00;
  border: none;
  border-radius: 5px;
  padding: 7px;
  box-shadow: none;
  margin: 0px 0 0px 0;
  /* font-weight: bold; */
  font-size: 1.5em;
  text-shadow: none;
  outline: none !important;
  width: 100%;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Teko', Sans-Serif;
}
.btn-primary:disabled {
  background: #f98c009e;
  cursor: not-allowed;
}
.quick-link {
  display:block;
  height: 20px;
}
.quick-link a {
  float: right;
  color: #ffffff;
}
.alert {
  color: red;
  font-size: 20px;
  height: 30px;
  display:block;
  margin-bottom: 5px;
}
/***********************************************
	Return to top button
***********************************************/
.return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 2px 3px 5px #11111069;
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 25px;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 200;
}
.show {
  opacity: 1;
  /* display:block; */
}
.return-to-top span {
  color: #fff;
  margin: 0;
  position: relative;
  /* left: 13px; */
  top: 5px;
  font-size: 40px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
  bottom: 25px;
}
.return-to-top:hover span {
  color: #fff;
  top: 1px;
}
.return-to-top div {
  width: 40%;
  height: 4px;
  background: #ffffff;
  border-radius: 1000px;
  opacity: 1;
  transition: all .3s ease;
}
.return-to-top div:nth-child(1), .return-to-top div:nth-child(3) {
  transform: rotate(50deg) translateY(-4px) translateX(21px);
}
.return-to-top div {
  margin: 5px;
}
.return-to-top div:nth-child(2), .return-to-top div:nth-child(4) {
  transform: rotate(-50deg) translateY(7px) translateX(0px);
}
.return-to-top.active div:nth-child(1), .return-to-top.active div:nth-child(3) {
  transform: rotate(50deg) translateY(-11px) translateX(14px)
}
.return-to-top.active div:nth-child(2), .return-to-top.active div:nth-child(4) {
  transform: rotate(-50deg) translateY(1px) translateX(8px);
}
/*********************************************************************************
                          For desktop:
*********************************************************************************/
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

/******************************************************************************
					For mobile phones:
******************************************************************************/
@media only screen and (max-width: 1000px)
			/*, screen and (max-height: 600px) { */
{
        [class*="col-"] {
        width: 100%;
  }
  .sideContainer {
    display:none;
    height: 100vh !important;
    width: 98% !important;
    padding: 0% 1% !important;
  }
  .contentContainer {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .subBanner {
    flex-wrap: wrap !important;
  }
  .summary {
    width: 90% !important;
    padding: 5% !important;
  }
  .summary p {
    margin: 20px 3px;
    font-size: 18px;
  }
  .summary h2 {
    font-size: 25px !important;
  }
  .header{
    display: none;
  }
  .mobileHeader {
    display: inline-flex !important;
  }
  .header-profile {
    display: none !important;
  }
  .header h1 {
      display: none;
  }
  .title {
      font-size: 7em !important;
      height: 120px;
    /*  padding-top: 126px !important;
      margin: 0 12px;
      /* line-height: 100px !important; */
   /*line-height: 22vw; */
  }
  .subBanner p{
    font-size: 16px !important;
    text-align: left !important;
  }
  .banner {
      height:300px !important;
      min-height: 440px !important;
      /* background-position-x: -254px; */
      background-position-y: 60px !important;
  }
  .banner::before{
    min-height:440px !important;
  }
  .main {
    min-height: 600px !important;
  }
  .main::before {
    min-height: 600px !important;
  }
  .titleDesc {
    font-size: 3em;
    margin: 0 10px;
    line-height: 42px;
    position: relative;
    top: 50px;
    text-shadow: 2px 2px 20px #111110;
    opacity: 1.0;
    transform: scale(1);
    transition-duration: 0.5s;
  }
  .titleBtn {
    position: absolute;
    margin-bottom: -338px;
  }
  .contact-form {
    padding-top: 182px;
    padding-bottom: 50px;
    display: block;
    width:98%;
    margin-left: 1%;
  }
  .interest-form {
    padding-top: 0px;
    margin-top: 0px;
}
  .med-width {
    width: auto;
  }
  .message {
    padding: 5%;
    width: 90%;
  }
    .pageContent, .pageContentWhite {
      font-family: 'Lao UI',sans-serif;
      padding: 3% 4% 3% 4%;
      width: 92%;
  }
  .pageContent p, .pageContentWhite p,  .pageContent h2, .pageContentWhite h2, .pageContent h4, .pageContentWhite h4 {
    width: 100% !important;
    margin: 20px 0px !important;
  }
  .pageContent h2, .pageContentWhite h2, .pageContent h4, .pageContentWhite h4 {
    text-align: left!important;
  }
  .pageContent h3 {
    margin: 36px 0 0 0;
  }
  .pageContent p, .pageContentWhite p, .pageContent ul li, .pageContentWhite ul li {
    font-size: 16px !important;
    text-align: left !important;
  }
  .slider {
    width: 100%!important;
    margin: 0%!important;
  }
  .footer {
    width: 100%!important;
    margin-left: 0%!important;
  }
  .footer ul {
    width: 100%!important;
    margin-top:0px!important;
    text-align: center!important;
  }
  .footer ul li ul {
    width: 100%!important;
    margin-top:40px;
    display:none;
  }
  /* .form-control {
    width: 80% !important;
  } */
}
@media only screen and (max-width: 1000px) {
  .title {
    height:auto;
    font-size: 5em !important;
  }
}
@media only screen and (max-width: 380px) {
  .form-group label {
    font-size: 16px;
}
}

/***********************************************
 For laptops   
 ***********************************************/
 @media only screen and (max-width: 1650px) {
 
}
/***********************************************
 For Tablets and medium size screened devices   
 ***********************************************/
@media only screen and (max-width: 1220px) {
	
}
